<template>
  <div class="bpr-bets">
    <div class="bpr-bets-title" v-if="Object.keys(getBetsByGameType).length > 0">
      <div class="bpr-bets-title__cont-info-title">
        <div class="bpr-bets-title__cont-info-title__name">Realiza tu apuesta en</div>
        <div class="bpr-bets-title__cont-info-title__logo">
          <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020_urlblanca.svg" width="70" />
        </div>
      </div>
    </div>
    <div class="bpr-bets-subtitle" v-if="Object.keys(getBetsByGameType).length > 0">
      <span class="bpr-bets-subtitle__regular">Estos son los&nbsp;</span
      ><span class="bpr-bets-subtitle__bold">Momios vigentes en Caliente</span>
    </div>
    <template v-for="(betGroup, keyGroup) in getBetsByGameType">
      <template v-if="keyGroup == 0">
        <details open :key="keyGroup + 'keyGrouphref0'">
          <summary class="bpr-bets-item firstitem">
            <div class="bpr-bets-item-title">{{ betGroup.name }}</div>
          </summary>
          <template v-for="(bet, keybet) in betGroup.bets">
            <template v-if="bet.bet_ref != ''">
              <div class="bpr-bets-item-momio" :key="keybet + 'bethref0'" @click="openCalienteBet(bet, betGroup.name)">
                <div class="bpr-bets-item-typebet">{{ bet.suggestion }}</div>
                <div class="bpr-bets-item-odd">{{ bet.odd }}</div>
              </div>
            </template>
            <template v-else>
              <div class="bpr-bets-item-momio" :key="keybet + 'betsinhref0'">
                <div class="bpr-bets-item-typebet">{{ bet.suggestion }}</div>
                <div class="bpr-bets-item-odd">{{ bet.odd }}</div>
              </div>
            </template>
          </template>
        </details>
      </template>
      <template v-else>
        <details :key="keyGroup + 'keyGrouphref'">
          <summary class="bpr-bets-item ">
            <div class="bpr-bets-item-title">{{ betGroup.name }}</div>
          </summary>
          <template v-for="(bet, keybet) in betGroup.bets">
            <template v-if="bet.bet_ref != ''">
              <div class="bpr-bets-item-momio" :key="keybet + 'bethref'" @click="openCalienteBet(bet, betGroup.name)">
                <div class="bpr-bets-item-typebet">{{ bet.suggestion }}</div>
                <div class="bpr-bets-item-odd">{{ bet.odd }}</div>
              </div>
            </template>
            <template v-else>
              <div class="bpr-bets-item-momio" :key="keybet + 'betsinhref'">
                <div class="bpr-bets-item-typebet">{{ bet.suggestion }}</div>
                <div class="bpr-bets-item-odd">{{ bet.odd }}</div>
              </div>
            </template>
          </template>
        </details>
      </template>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { doBetcaliente } from '@/utils/analytics';
export default {
  name: 'BetBets',
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'refreshIntervalCounter']),
    ...mapGetters('betsGeneral', ['getBetsByGameType', 'getRightBetComponent']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  props: {
    gameId: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    getRightBetComponent: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue) {
            if (newValue !== 'Result') {
              await this.getBets(this.gameId);
            }
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
    gameId: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue) {
            await this.getBets(newValue);
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
    refreshIntervalCounter() {
      this.getBets(this.gameId);
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getBetsByType']),
    openCalienteBet(bet, groupName) {
      let sections = {
        Corners: 'Tiros de esquina',
        Goals: 'Goles',
      };
      const index = bet.bet_ref.indexOf('BET_REF=');
      const betRef = bet.bet_ref.slice(index + 8);
      doBetcaliente({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        value: betRef,
        odd: bet.odd,
        section: `Realiza tu apuesta en caliente (${sections[this.getRightBetComponent]})`,
        game_id: this.gameId,
        bet_name: `${groupName} (${bet.suggestion})`,
      });
      window.open(bet.bet_ref, '_blank');
    },
    async getBets(game) {
      let type = 2;
      if (this.getRightBetComponent === '' || this.getRightBetComponent === 'Corners') {
        type = 2;
      }
      if (this.getRightBetComponent === 'Goals') {
        type = 3;
      }
      await this.getBetsByType({ game: game, type: type });
    },
  },
};
</script>
<style>
.bpr-bets-item-odd {
  width: auto;
  height: 29px;
  display: inline-block;
  padding-left: 1px;
  padding-right: 1px;
  margin-top: 10px;
  line-height: 29px;
  margin-right: 14px;
  font-family: Roboto-Black, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #132839;
}
.bpr-bets-item-typebet {
  width: auto;
  height: 29px;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  background-color: #ceef74;
  font-family: 'Roboto-Medium';
  margin-top: 10px;
  line-height: 29px;
  margin-left: 16px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #132839;
}
.bpr-bets-item-momio {
  width: 99.4%;
  height: 49px;
  margin-top: 4px;
  border-radius: 8px;
  border: solid 1px #cbee6b;
  display: inline-flex;
  background: white;
  justify-content: space-between;
}
.bpr-bets-item-momio:hover {
  cursor: pointer;
  border: solid 1px #bae24b;
}
.bpr-bets-item-momio:hover > .bpr-bets-item-typebet {
  background-color: #bae24b;
}
.bpr-bets-item-momio:hover > .bpr-bets-item-odd {
  color: #9ccb1c;
}
.firstitem {
  margin-top: 24px !important;
}
.bpr-bets-item {
  width: 100%;
  position: relative;
  height: 31.5px;
  border-radius: 5px;
  background-color: #173751;
  margin-top: 8px;
}
.bpr-bets-item:hover {
  cursor: pointer;
}
.bpr-bets-item-title {
  width: 85%;
  height: 31.5px;
  top: 0px;
  position: absolute;
  text-align: left;
  line-height: 31.5px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  padding-left: 43px;
  letter-spacing: normal;
  color: #fff;
  font-family: 'Roboto-Medium';
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media screen and (max-width: 463px) {
  .bpr-bets-item-title {
    font-size: 14px;
    width: 83%;
  }
}
@media screen and (max-width: 363px) {
  .bpr-bets-item-title {
    font-size: 12px;
    width: 78%;
  }
}
details > .bpr-bets-item::before {
  content: url('/images/icn_expand.png');
  color: white;
  font-size: 1em;
  float: left;
  margin-top: 2px;
  width: 10px;
  height: 10px;
  margin-left: 16px;
}

details[open] > .bpr-bets-item::before {
  content: url('/images/icn_collapse.png');
  color: rgb(255, 255, 255);
  font-size: 1em;
  float: left;
  margin-top: 2px;
  margin-left: 16px;
  width: 10px;
  height: 10px;
}
</style>
<style lang="scss">
.bpr-bets {
  width: 95%;
  height: auto;
  margin: 0 auto;
  margin-top: 45px;
}
.bpr-bets-subtitle {
  width: 100%;
  height: 15px;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 15px;
  &__regular {
    font-family: 'Roboto-Medium';
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #132839;
    @media screen and (max-width: 480px) {
      font-size: 17px;
    }
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
  &__bold {
    font-family: 'Roboto-Bold';
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #132839;
    @media screen and (max-width: 480px) {
      font-size: 17px;
    }
    @media screen and (max-width: 336px) {
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      font-size: 15px;
    }
  }
}
.bpr-bets-title {
  width: 100%;
  height: 34px;
  border-radius: 7px;
  background-color: #222527;
  &__cont-info-title {
    width: 286px;
    height: 34px;
    margin: 0 auto;
    display: inline-flex;
    justify-content: space-between;
    @media screen and (max-width: 363px) {
      width: 250px;
    }
    &__name {
      width: 192px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      font-family: 'Roboto-Medium';
      @media screen and (max-width: 363px) {
        width: 180px;
        font-size: 16px;
      }
    }
    &__logo {
      width: 70.7px;
      height: 34px;
      img {
        margin-top: 3px;
        @media screen and (max-width: 363px) {
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
